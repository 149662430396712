<template>
    <div> </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: 'DashboardOverview'
})
</script>
